import React from "react"
import '@formatjs/intl-locale/polyfill'
import '@formatjs/intl-getcanonicallocales/polyfill'
import { injectIntl } from "gatsby-plugin-react-intl"
import Layout from "../components/layout"
import Seo from "../components/seo"
import ClientsTicker from "../components/misc/clients-ticker";
import HeroData from "../content/assets/hero.json"
import TickerData from "../content/home/ticker.json"
import SimpleMailingList from "../components/ctas/simple-mailing-list"
import HeroSubpage from "../components/heroes/hero-subpage"
import NotificareContent from "../components/notificare"

const AssetsPage = ({ intl }) => (
    <Layout>
        <Seo lang={intl.locale}
             title={intl.formatMessage({ id: "pages.assets.title" })}
             description={intl.formatMessage({ id: "pages.assets.description" })}
        />
        <HeroSubpage
            theme={`dark`}
            content={HeroData[intl.locale]}
        />
        <NotificareContent contentType={`assets`} />
        <ClientsTicker content={TickerData[intl.locale]} />
        <SimpleMailingList />
    </Layout>
)

export default injectIntl(AssetsPage)
